import ApplicationController from '../application_controller';
import CableReady from 'cable_ready'
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  connect() {
    console.log("DemandController")
    consumer.subscriptions.create(
      {
        channel: "DemandChannel",
        demandable_date: this.element.dataset.demandableDate,
        account_id: this.element.dataset.accountId
      },
      {
        connected () {
          console.log("DemandChannel Connected")
        },
        received (data) {
          console.log("Inside DemandChannel#received")
          if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
}
