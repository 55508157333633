import { Controller } from "stimulus"

export default class extends Controller {

  toggle(event) {
    var id = event.target.closest('tr').getAttribute('data-id');
    var element = document.getElementById(`order-${id}-expandable`);

    element.classList.toggle('tw-hidden')
  }
}
