import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "filterable", "balance" ]
  static values = { totalCount: Number, filteredCount: Number }
  static classes = [ "filteredOut" ]

  connect() {
    this.countFilterables();
  }

  filter(event) {
    if (event) { event.preventDefault(); }
    let lowerCaseFilterTerm = this.lowerCasedFilterTerm(event);
    let filteredOutCount = 0;
    this.filterableTargets.forEach((filterable) => {
      const keyMatched = filterable.dataset.filterKey.toLowerCase().includes( lowerCaseFilterTerm )
      filterable.classList.toggle(this.filterOutClass, !keyMatched )
      if (!keyMatched) { filteredOutCount +=1; }
    })
    this.filteredCountValue = this.totalCountValue - filteredOutCount;

  }

  filteredCountValueChanged() {
    if (this.hasBalanceTarget) {
      this.balanceTarget.innerText = `Showing ${this.filteredCountValue} of ${this.totalCountValue}`;
    }
  }

  // private

  countFilterables() {
    let filteredOutCount = 0;
    this.filterableTargets.forEach(filterable => {
      if (filterable.classList.contains(this.filterOutClass) ) {
        filteredOutCount += 1;
      }
    })
    this.totalCountValue = this.filterableTargets.length;
    this.filteredCountValue = this.totalCountValue - filteredOutCount
  }

  lowerCasedFilterTerm(event) {
    if (this.hasSourceTarget) {
      return this.sourceTarget.value.toLowerCase();
    } else if (event) {
      return event.target.value.toLowerCase();
    } else {
      console.log("You need to select a sourceTarget")
    }
  }

  get filterOutClass() {
    return this.hasFilteredOutClass ? this.fiteredOutClass : 'tw-hidden'
  }

}
