import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleable']
  static values = { open: Boolean }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle(event) {
    event.preventDefault()

    if (event.target.type == 'checkbox') {
      this.openValue = event.target.checked
    } else {
      this.openValue = !this.openValue
    }
  }

  hide(event) {
    event.preventDefault();

    this.openValue = false;
  }

  show(event) {
    event.preventDefault();

    this.openValue = true;
  }

  openValueChanged() {
    if (!this.toggleClass) { return }

    this.toggleableTargets.forEach(target => {
      if (this.openValue) {
        target.classList.add(this.toggleClass)
      } else {
        target.classList.remove(this.toggleClass)
      }
    })
  }
}
