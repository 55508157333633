import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['variant', 'selected', 'type'];

  async skipSelected() {
    event.preventDefault();
    const VariantIds = this.checkedVariantTargets().map(element => element.dataset.id);
    const commerceID = this.element.getAttribute('data-commerce-id');
    const value = event.target.dataset.skip;

    await fetch(`/integrations/commerce/${commerceID}/variants/skips`, {
      method: 'PATCH',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'variant_ids': VariantIds, 'skip': value }),
    });
  }

  checkedVariantTargets() {
    return this.variantTargets.filter(order => order.querySelector('input').checked);
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}
