import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {

  static targets = [ "input" ]

  connect() {
    this.inputable = intlTelInput(this.inputTarget, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
        hiddenInput: "phone",
        initialCountry: "us",
        preferredCountries: ["us", "ca"],
        separateDialCode: true,
        autoPlaceholder: "aggressive",
        nationalMode: false,
        formatOnDisplay: true 
      }
    )
  }

  formatIntlTelInput() {
    var currentText = this.inputable.getNumber(intlTelInputUtils.numberFormat.E164);
    if (typeof currentText === 'string') { // sometimes the currentText is an object :)
        this.inputable.setNumber(currentText); // will autoformat because of formatOnDisplay=true
    }
  }
}
