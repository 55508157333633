import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['order', 'selected'];

  async importSelected(event) {
    event.preventDefault();
    const OrderIds = this.checkedOrderTargets().map(element => element.dataset.id);
    const commerceID = this.element.getAttribute('data-commerce-id')

    await fetch(`/integrations/commerce/${commerceID}/orders/import`, {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'order_ids': OrderIds }),
    });
  }

  async checkImportability(event) {
    event.preventDefault();
    const OrderIds = this.checkedOrderTargets().map(element => element.dataset.id);

    await fetch(`/commerce/orders/importability_checks`, {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'order_ids': OrderIds }),
    });
  }

  async updateImportStatus(event) {
    event.preventDefault();
    const OrderIds = this.checkedOrderTargets().map(element => element.dataset.id);
    const importStatus = event.target.closest('[data-import-status]').getAttribute('data-import-status');
    const commerceID = this.element.getAttribute('data-commerce-id')

    await fetch(`/integrations/commerce/${commerceID}/orders/import_statuses`, {
      method: 'PATCH',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'order_ids': OrderIds, 'import_status': importStatus }),
    });
  }

  // private

  checkedOrderTargets() {
    return this.orderTargets.filter(order => order.querySelector('input').checked);
  }


  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}
