import ApplicationController from './application_controller'

export default class extends ApplicationController {

  chainTo(controller) {
    this.chainedController = controller
    this.updateChainedOptions()
  }

  updateChainedOptions() {
    this.chainedController.showOptions(this.element.value)
  }
}