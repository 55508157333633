import { Controller } from 'stimulus'

const easeInStage = ['tw-opacity-100', 'tw-ease-in', 'tw-duration-100'];
const easeOutStage = ['tw-opacity-0', 'tw-ease-out', 'tw-duration-100'];
const DISABLED_BG = 'tw-bg-gray-200';
const ENABLED_BG = 'tw-bg-teal-300';
const TRANSLATE_X_0 = 'tw-translate-x-0';
const TRANSLATE_X_5 = 'tw-translate-x-5'; 

export default class extends Controller {
  static targets = [
    'background',
    'switch',
    'slider',
    'disableIcon',
    'enableIcon',
    'spinner',
    'success',
    'error'
  ]

  static outlets = [
    'toggle',
    'able'
  ]

  addCss(options) {
    this.backgroundTarget.classList.add(options.bgColor);
    this.sliderTarget.classList.add(options.translateX);
    this.disableIconTarget.classList.add(...options.crossIconAnimation);
    this.enableIconTarget.classList.add(...options.checkedIconAnimation);
  }

  removeCss(options) {
    this.backgroundTarget.classList.remove(options.bgColor);
    this.sliderTarget.classList.remove(options.translateX);
    this.disableIconTarget.classList.remove(...options.crossIconAnimation);
    this.enableIconTarget.classList.remove(...options.checkedIconAnimation);
  }

  toggle(event) {
    if (this.hasAbleOutlet) {
      this.ableOutlets.forEach(outlet => {
        outlet.toggle()
      })
    }
    this.spinnerTarget.classList.remove('tw-hidden');

    if (this.switchTarget.checked && this.switchTarget.dataset.params) {
      if (this.url) {
        this.sync(this.url, 'POST', this.switchTarget.checked, this.params)
      } else {
        this.spinnerTarget.classList.add('tw-hidden');
      }
      this.enableSwitch()
    } else if (this.switchTarget.checked) {
      if (this.url) {
        this.sync(this.url, 'PATCH', this.switchTarget.checked, this.params)
      } else {
        this.spinnerTarget.classList.add('tw-hidden');
      }
      this.enableSwitch()
    } else if (this.switchTarget.dataset.params) {
      this.disableSwitch()
      if (this.url) {
        this.sync(this.url, 'DELETE', this.switchTarget.checked, this.params)
      } else {
        this.spinnerTarget.classList.add('tw-hidden');
      }
    } else {
      this.disableSwitch()
      if (this.url) {
        this.sync(this.url, 'PATCH', this.switchTarget.checked, this.params)
      } else {
        this.spinnerTarget.classList.add('tw-hidden');
      }
    }
    if (this.hasToggleOutlet) {
      console.log('has toggle outlet', this.toggleOutlets)
      this.toggleOutlet.toggle(event)
    }
  }

  sync(url, method, state, params) {
    fetch(url, {
      method: method,
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: this.switchTarget.dataset.params ? params : JSON.stringify(params)
    })
      .then(response => {
        if (response.ok) {
          setTimeout(() => {
            this.spinnerTarget.classList.add('tw-hidden');
            this.showSuccess();
          }, 250);
        } else {
          setTimeout(() => {
            this.spinnerTarget.classList.add('tw-hidden');
            this.showError();
            this.revertState(state);
          }, 250);
        }
      })
      .catch(_error => {
        this.showError();
      }) 
  }

  enableSwitch() {
    this.removeCss({
      bgColor: DISABLED_BG,
      translateX: TRANSLATE_X_0,
      crossIconAnimation: easeInStage,
      checkedIconAnimation: easeOutStage,
    });
    this.addCss({
      bgColor: ENABLED_BG,
      translateX: TRANSLATE_X_5,
      crossIconAnimation: easeOutStage,
      checkedIconAnimation: easeInStage,
    });
  }

  disableSwitch() {
    this.removeCss({
      bgColor: ENABLED_BG,
      translateX: TRANSLATE_X_5,
      crossIconAnimation: easeOutStage,
      checkedIconAnimation: easeInStage,
    });
    this.addCss({
      bgColor: 'tw-bg-gray-200',
      translateX: 'tw-translate-x-0',
      crossIconAnimation: easeInStage,
      checkedIconAnimation: easeOutStage,
    });
  }

  revertState(state) {
    if (state == true) {
      this.disableSwitch()
      this.switchTarget.checked = false
    } else {
      this.enableSwitch()
      this.switchTarget.checked = true
    }
  }

  toggleOnly() {
    this.revertState(this.switchTarget.checked)
  }

  get params() {
    let data = {}
    data[this.switchTarget.dataset.object] = {
      [this.switchTarget.dataset.attribute]: this.switchTarget.checked
    }
    
    return this.switchTarget.dataset.params ? this.switchTarget.dataset.params : data;
  }

  get attribute() {
    return this.switchTarget.dataset.attribute
  }

  showError() {
    this.errorTarget.classList.remove('tw-hidden');
    setTimeout(() => {
      this.errorTarget.classList.add('tw-hidden');
    }, 950);
  }

  showSuccess() {
    this.successTarget.classList.remove('tw-hidden');
    setTimeout(() => {
      this.successTarget.classList.add('tw-hidden');
    }, 950);
  }

  get url() {
    return this.switchTarget.dataset.url
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
