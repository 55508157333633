import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['customer', 'selected'];

  async create() {
    event.preventDefault();
    const customerIds = this.checkedCustomerTargets().map(element => element.dataset.id);
    const accountingId = this.element.getAttribute('data-accounting-id');
    await fetch(`/integrations/accounting/${accountingId}/customers/create_customer`, {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'customer_ids': customerIds }),
    });
  }

  checkedCustomerTargets() {
    return this.customerTargets.filter(order => order.querySelector('input').checked);
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}
