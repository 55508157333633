import ApplicationController from '../application_controller';
import CableReady from 'cable_ready'
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  connect() {
    consumer.subscriptions.create(
      {
        channel: "DemandIndicatorChannel",
        task: this.element.dataset.task,
        account_id: this.element.dataset.accountId
      },
      {
        connected () {
        },
        received (data) {
          if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
}
