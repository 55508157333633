import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String, delay: Number };

  show() {
    const delay = this.delayValue || 0

    this.timeout = setTimeout(() => {
      this.showContent()
    }, delay)
  }

  showContent() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("hidden");
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          this.element.appendChild(fragment);
        });
    }
  }

  hide() {
    clearTimeout(this.timeout);
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
