import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['rangeSelect', 'startDate', 'endDate', 'startDiv', 'endDiv']

  connect() {
    this.toggleClass = this.data.get('class') || 'tw-invisible'
    this.showInputs()
  }

  setDates() {
    if (this.hasStartDateTarget && this.rangeSelectTarget.value != 'custom') {
      const date_array = JSON.parse(this.rangeSelectTarget.value)
      this.startDateTarget.value = date_array[0] || ''
      this.endDateTarget.value = date_array[1] || ''
      console.log(JSON.parse(this.rangeSelectTarget.value))
      console.log(this.rangeSelectTarget.value)
    }
  }

  showInputs() {
    if (this.rangeSelectTarget.value != 'custom') {
      this.startDivTarget.classList.add(this.toggleClass)
      this.endDivTarget.classList.add(this.toggleClass)
    } else {
      this.startDivTarget.classList.remove(this.toggleClass)
      this.endDivTarget.classList.remove(this.toggleClass)
    }
  }

}