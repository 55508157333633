import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['type', 'charge', 'rate', 'createShippingLabelCheckbox'];
  static values = { deliveryMethodTypes: Array };

  toggleFields() {
    const deliveryMethodType = this.getDeliveryMethodType(this.typeTarget.value);

    if (deliveryMethodType) {
      this.chargeTarget.disabled = !deliveryMethodType.charge_required;
      this.rateTarget.disabled = !deliveryMethodType.rate_required;
      this.createShippingLabelCheckboxTarget.disabled = deliveryMethodType.require_label;
      if (!this.typeTarget.disabled) this.createShippingLabelCheckboxTarget.checked = deliveryMethodType.require_label;
    } else {
      this.chargeTarget.disabled = true;
      this.rateTarget.disabled = true;
      this.createShippingLabelCheckboxTarget.disabled = true;
      this.createShippingLabelCheckboxTarget.checked = false;
    }
  }

  getDeliveryMethodType(selectedType) {
    return this.deliveryMethodTypesValue.find(deliveryMethodType => deliveryMethodType.klass_name === selectedType) || null;
  }
}
