import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['discountType', 'discountInputField'];
  static classes = ['hidden'];

  changeDiscountType() {
    this.hideDiscountTypeTargets();
    this.resetDiscountInputFieldValue();
    this.discountTypeElement.classList.remove(this.hiddenClass);
  }

  hideDiscountTypeTargets() {
    this.discountTypeTargets.forEach(target => target.classList.add(this.hiddenClass));
  }

  resetDiscountInputFieldValue() {
    this.discountInputFieldTargets.forEach(target => target.value = '');
  }

  get discountTypeElement() {
    return this.discountTypeTargets.find(element => element.id === event.target.dataset.discountTypeKind);
  }
}
