import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'checkbox', 'errorContent']

  connect() {
    this.placeholder = this.containerTarget.innerHTML;
    this.calculate()
  }

  calculate() {
    this.containerTarget.insertAdjacentHTML('afterbegin','<span class="element-spinner masked"></span>')
    setTimeout(() => {
      this.refreshOrShowPlaceholder()
    }, 5)
  }

  // private

  refreshOrShowPlaceholder() {
    let query_string = this.getQueryString()
      if (query_string.length > 0) {
        this.fetchHTML(query_string);
      } else {
        this.containerTarget.innerHTML = this.placeholder;
      }
  }

  getQueryString() {
    let query_string = ""
    this.checkboxTargets.forEach((e, i) => {
      if (e.checked == true) {
        query_string += '&order_ids%5B%5D=' + e.value
      }
    })
    return query_string.slice(1)
  }

  fetchHTML(query_string) {
    fetch("/demands?" + query_string)
      .then(response => {
        if (response.ok) {
          response.blob()
            .then(response => response.text())
            .then(html => { this.containerTarget.innerHTML = html })
        } else {
          this.showError();
        }
      })
      .catch(_error => {
        this.showError();
      })
  }

  showError() {
    this.containerTarget.innerHTML = this.errorContentTarget.innerHTML;
  }
}
