import ApplicationController from '../application_controller';
import CableReady from 'cable_ready'
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  connect() {
    consumer.subscriptions.create(
      {
        channel: "RefreshChannel",
        page_identifier: this.element.dataset.pageIdentifier,
        account_id: this.element.dataset.accountId,
      },
      {
        connected () {
        },
        received (data) {
          if (data.cableReady) {
            CableReady.perform(data.operations);
          }
        }
      }
    )
  }
}
