import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['greenCoffeeFilter', 'preBlendFilter', 'type', 'greenCoffeeIds', 'preBlendIds'];

  connect() {
    this.toggleFilters();
  }

  toggleFilters() {
    const selectedFilter = this.typeTarget.value;
    if (selectedFilter === 'green_coffee') {
      this.greenCoffeeFilterTarget.style.display = 'block';
      this.preBlendFilterTarget.style.display = 'none';
      this.preBlendIdsTarget.value = null;
    } else if (selectedFilter === 'pre_blend') {
      this.greenCoffeeFilterTarget.style.display = 'none';
      this.preBlendFilterTarget.style.display = 'block';
      this.greenCoffeeIdsTarget.value = null;
    } else {
      this.greenCoffeeFilterTarget.style.display = 'block';
      this.preBlendFilterTarget.style.display = 'block';
    }
  }
}
