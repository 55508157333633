import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['country', 'province'];
  static outlets = ['tom-select'];

  setCountryInProvince() {
    const provinceDropdown = this.provinceTarget.tomselect;

    provinceDropdown.clear();
    provinceDropdown.clearOptions();
    const remoteLoad = this.tomSelectOutlet.remoteLoad('/searches/provinces/', {"country": this.countryTarget.value});
    provinceDropdown.settings.load = remoteLoad['load'];
  }
}
