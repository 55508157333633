import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'errorContent']

  connect() {
    if (this.data.get('wait') != "true") {
      this.load()
    } else {
      this.lazyLoad()
    }
  }

  load() {
    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()

    setTimeout(() => {
      this.fetchHTML()
      }, 5)
  }

  fetchHTML() {
    fetch(this.data.get("url"))
        .then(response => {
          if (response.ok) {
            response.blob()
              .then(response => response.text())
              .then(html => { this.containerTarget.innerHTML = html })
          } else {
            this.showError();
          }
        })
        .catch(_error => {
          this.showError();
        })
  }

  lazyLoad () {
    const options = {
      threshold: this.lazyLoadingThresholdValue,
      rootMargin: this.lazyLoadingRootMarginValue || '0px'
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.load()
          observer.unobserve(entry.target)
        }
      })
    }, options)

    observer.observe(this.element)
  }

  // private

  showError() {
    this.containerTarget.innerHTML =  this.errorNotification
    Honeybadger.notify('Content loader failed to load', this.data.get("url"))
  }

  get errorNotification() {
    if (this.hasErrorContentsTarget) {
      return this.errorContentTarget.innerHTML
    }
    else {
      return '<span class="tw-w-full tw-text-gray-400">Content could not be loaded.</span>'
    }
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
      console.log('Content loader aborted!')
    }
  }
}