import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['source', 'output']

  static values = {
    type: { type: Number, default: 0 },
    delay: { type: Number, default: 0 }
  }

  static outlets = [
    'paste'
  ]

  clone(event) {
    if (this.hasPasteOutlet) {
      console.log("cloning")
      this.pasteOutlets.forEach(paste => {
        let value = this.getContentValue(event)
        paste.update(paste.outputTarget, value)
      })
    } else {
      this.getContentValue(event)
    }
  }

  // Private

  getContentValue(event) {
    switch(this.typeValue) {
      case 0:
        // Copy source's value
        return this.sourceTarget.value
      case 1:
        // Copy the event's data-content-value
        return event.target.dataset.contentValue
    }
  }

  paste(event) {
    this.timeout = setTimeout(() => {
      this.pasting(event)
    }, this.delayValue)
  }

  pasting(event) {
    let contentValue = (this.hasUseTargetInputValue && this.useTargetInputValue) ?
      this.inputTarget.value :
      event.target.dataset.contentValue
    if (this.hasOutputTarget) {
      this.outputTargets.forEach(target => {
        target.value = contentValue

        const e = new Event("change");
        target.dispatchEvent(e)
      })
    }
  }

  pasting_from_tom_select(event) {
    let contentValue = event.target.value
    if (this.hasOutputTarget) {
      this.outputTargets.forEach(target => {
        target.value = contentValue
      })
    }
  }

  selectAll(event) {
    event.target.select()
  }
}
