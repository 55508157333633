import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cardElement', 'cardErrors', 'form', 'submit']

  connect() {
    this.stripe = Stripe(this.data.get('key'))
    this.elements = this.stripe.elements()

    this.configureCard()

    this.formTarget.addEventListener('turbo:submit-end', () => {
      this.submitTarget.disabled = false
    })
  }

  saveCard() {
    this.submitTarget.disabled = true

    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        this.cardErrorsTarget.textContent = result.error.message
      } else {
        this.submitFormWithToken(result.token)
      }
    })
  }

  // PRIVATE

  configureCard() {
    this.card = this.elements.create('card', {
      style: this.cardStyle
    });

    this.card.mount(this.cardElementTarget)

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', (event) => {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    })
  }

  submitFormWithToken(token) {
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripeToken')
    hiddenInput.setAttribute('value', token.id)
    this.formTarget.appendChild(hiddenInput)

    this.formTarget.requestSubmit()
  }

  get cardStyle() {
    return {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
  }
}
