import ApplicationController from '../application_controller';
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  connect() {
    consumer.subscriptions.create(
      {
        channel: "OnlineChannel",
        controller_name: this.element.dataset.contName,
        action_name: this.element.dataset.actionName,
      },
      {
        connected () {
          console.log("OnlineChannel Connected")
        },
        received (data) {
          console.log("Inside OnlineChannel#received")
          const container = document.getElementById("online_users")
          const user_target = `user_${userId}`
          container.innerHTML = data.html;
          document.getElementById(user_target).remove()
      }
    })
    const userId = this.element.dataset.userId
  }
}
