import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "destination" ]
  static values = {
    wrapperSelector: {
      type: String,
      default: '.nested-form-wrapper'
    }
  }

  add(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace( /TEMPLATE_RECORD_INDEX/g, new Date().valueOf() );
    this.destinationTarget.insertAdjacentHTML('beforeend', content);

  }

  reset() {
    const wrapper = event.target.closest(this.wrapperSelectorValue)
    const input = wrapper.querySelector("input[name*='_destroy']");
    input.value = '1';

    wrapper.style.display = 'none';

    const template = this.templateTarget.innerHTML.replace( /TEMPLATE_RECORD_INDEX/g, new Date().valueOf() );
    wrapper.insertAdjacentHTML('afterend', template);
  }

  remove(event) {
    event.preventDefault()

    // @ts-ignore
    const wrapper = event.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'
    }
  }
}
