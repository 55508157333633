import { Controller } from "stimulus"

export default class extends Controller {
  static values = { target: String }

  connect() {
    this.element.style.setProperty("opacity", 0.4)
    this.element.style.setProperty("pointer-events", "none")
  }

  enable(event) {
    if (event.target.id == this.targetValue) {
      this.element.style.removeProperty("opacity")
      this.element.style.removeProperty("pointer-events")
    }
  }
}