import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['video', 'toggleable'];
  open() {
    this.toggleableTarget.classList.remove('tw-hidden');
    this.videoTarget.currentTime = 0;
    this.videoTarget.play();
  }

  close() {
    if (event.target === this.videoTarget) {
      event.stopPropagation()
      return
    }

    this.toggleableTarget.classList.add('tw-hidden');
    this.videoTarget.pause();
  }
}
