import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['id', 'shopifyId', 'sku', 'successContainer', 'failureContainer']

    connect() {
        if (this.hasSkuTarget) {
            // console.log(this.data.get("update-url"))
        }
    }

    updateSku(event) {
        event.preventDefault()
        let formData = new FormData()
        formData.append("variant[id]", this.idTarget.value);
        formData.append("external_variant_id", this.shopifyIdTarget.value)

        fetch(this.data.get("update-url"), {
                body: formData,
                method: 'PATCH',
                dataType: 'script',
                credentials: "include",
                headers: {
                    "X-CSRF-Token": this.getMetaValue("csrf-token")
                }
            }).then(response => {
                if (response.ok) {
                    console.log('succcess!')
                    this.successContainerTarget.classList.remove("tw-hidden")
                    this.failureContainerTarget.classList.add("tw-hidden")
                    this.skuTarget.innerHTML = '<a href=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="tw-h-6 tw-w-6 tw-fill-current tw-stroke-current tw-text-blue-500"><defs><style>.fa-secondary{opacity:.4}</style></defs><path d="M0 500V299.67a12 12 0 0 1 12-12h200.33a12 12 0 0 1 12 12v47.41a12 12 0 0 1-12.57 12l-101.87-4.88a176.07 176.07 0 0 0 317.25-56.94 12 12 0 0 1 11.67-9.26h49.09a12 12 0 0 1 11.8 14.18C478.07 417.08 377.19 504 256 504a247.43 247.43 0 0 1-188.76-87.17l4.13 82.57a12 12 0 0 1-12 12.6H12a12 12 0 0 1-12-12z" class="fa-secondary"/><path d="M12.3 209.82C33.93 94.92 134.81 8 256 8a247.4 247.4 0 0 1 188.9 87.34l-4-82.77A12 12 0 0 1 452.92 0h47.41a12 12 0 0 1 12 12v200.33a12 12 0 0 1-12 12H300a12 12 0 0 1-12-12v-47.41a12 12 0 0 1 12.57-12l101.53 4.88a176.07 176.07 0 0 0-317.24 56.94A12 12 0 0 1 73.19 224H24.1a12 12 0 0 1-11.8-14.18z" class="fa-primary"/></svg></a>'
                        // response.blob()
                        //   .then(response => response.text())
                        //   .then(html => { this.containerTarget.innerHTML = html })
                } else {
                    console.log('error!')
                    this.showError();
                }
            })
            .catch(_error => {
                this.showError();
            })
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content")
    }

    showError() {
        this.failureContainerTarget.classList.remove("tw-hidden")
        this.successContainerTarget.classList.add("tw-hidden")
    }
}