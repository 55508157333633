import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantity', 'basePrice', 'discount', 'subtotal', 'isFulfilled', 'inventory', 'fulfilledQuantity']

  connect() {
    this.updateSubtotal()
    this.checkIsfulfilled()
  }

  updateSubtotal() {
    const num = this.quantity * this.basePrice - this.totalDiscount()
    this.subtotalTarget.value = (Math.round(num * 100) / 100).toFixed(2);
  }

  // private

  totalDiscount() {
    const discount = parseFloat(this.discountTarget.value || 0);
    if (this.discountTarget.value.indexOf('%') >= 0) {
      return this.quantity * this.basePrice * discount / 100
    } else {
      return this.quantity * discount
    }
  }

  checkIsfulfilled() {
    if (this.hasIsFulfilledTarget) {
      this.isFulfilledTarget.querySelectorAll('input').forEach(input => {
        if (this.fulfilledQuantity !== 0 && this.fulfilledQuantity >= this.quantity) {
          input.checked = true
          input.disabled = false
        } else if (this.inventoryCount - (this.quantity - this.fulfilledQuantity ) < 0) {
          input.checked = false
          input.disabled = true
        } else {
          input.disabled = false
        }
      })
    }
  }

  get quantity() {
    return parseFloat(this.quantityTarget.value || 0)
  }

  get basePrice() {
    return parseFloat(this.basePriceTarget.value || 0)
  }

  get inventoryCount() {
    return parseInt(this.inventoryTarget.innerHTML)
  }

  get fulfilledQuantity() {
    return parseFloat(this.fulfilledQuantityTarget.value || 0)
  }
}
