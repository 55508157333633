import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["item", "selected", "replacementContainer", "replaceable"]

  update(event) {
    event.preventDefault();
    const url = event.currentTarget.dataset.url;
    event.currentTarget.innerHTML = this.replacementContainerTarget.innerHTML;
    fetch(url, { method: 'PUT', headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' }, body: JSON.stringify({}) })
  }

  updateGroup(event) {
    event.preventDefault();
    const resourceIds = this.itemTargets.map(element => element.dataset.resourceId);
    this.replaceableTargets.forEach(element => {
      if (element.dataset.fulfilled != 'true') {
        element.innerHTML = this.replacementContainerTarget.innerHTML;
      }
    });
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "resource_ids": resourceIds, "fulfill": true })
    });
  }

  updateAll(event) {
    event.preventDefault();
    const url = event.currentTarget.dataset.url;
    fetch(url, { method: 'PUT', headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' }, body: JSON.stringify({}) })
  }

  markAllAsComplete(event) {
    event.preventDefault();
    const resourceIds = this.selectedTargets.map(element => element.dataset.resourceId)

    this.selectedTargets.forEach(element => {
      let domId = element.dataset.domId
      if (element.dataset.completed != 'true') {
        document.querySelector(`span#${domId}`).innerHTML = this.replacementContainerTarget.innerHTML;
      }
    });
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "resource_ids": resourceIds, "fulfill": true })
    });
  }

  markSelectedAsComplete(event) {
    event.preventDefault();
    const resourceIds = this.checkedSelectedTargets().map(element => element.dataset.resourceId)

    this.checkedSelectedTargets().forEach(element => {
      let domId = element.dataset.domId
      if (element.dataset.completed != 'true') {
        document.querySelector(`span#${domId}`).innerHTML = this.replacementContainerTarget.innerHTML;
      }
    });
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "resource_ids": resourceIds, "fulfill": true })
    });
  }

  markSelectedAsIncomplete(event) {
    event.preventDefault();
    const resourceIds = this.checkedSelectedTargets().map(element => element.dataset.resourceId)

    this.checkedSelectedTargets().forEach(element => {
      let domId = element.dataset.domId
      if (element.dataset.completed == 'true') {
        document.querySelector(`span#${domId}`).innerHTML = this.replacementContainerTarget.innerHTML;
      }
    });
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "resource_ids": resourceIds, "fulfill": false })
    });
  }

  // private

  checkedSelectedTargets() {
    return this.selectedTargets.filter(selected => selected.checked)
  }

  replaceableSpans() {
    return document.querySelectorAll()
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
