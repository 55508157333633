/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "regenerator-runtime/runtime";
import 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/dist/svg-arrow.css'; // optional roundArrow

import "ahoy.js"

import 'intl-tel-input/build/css/intlTelInput.css';

require('expose-loader?$!jquery')

import Rails from "@rails/ujs"
window.Rails = Rails
Rails.start()

require("turbolinks").start()
require('channels')

import "controllers"
import '../css/application.css'

if (document.head.dataset.environment == 'test') {
    window.ajaxCounter = 0;
    (function() {
        var origOpen = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function() {
            window.ajaxCounter++;
            this.addEventListener('loadend', function() {
                window.ajaxCounter--;
            });
            origOpen.apply(this, arguments);
        };
    })();
}
