import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['summaryTasksCount', 'summaryTasksDisplayValue', 'summaryTasksMeasure', 'demandable', 'tasksCount', 'tasksMeasure', 'outstanding', 'carryover', 'operationTask']
  
  connect() {
    console.log('Plans connected!')
  }

  operationTaskTargetConnected(target) {
    this.updateRow(target)
    this.updateSummary()
  }

  operationTaskTargetDisconnected(target) {
    this.updateRow(target)
    this.updateSummary()
  }

  updateRow(target) {
    let parentValue = parseFloat(this.findDemandableTarget(target).dataset.plansParent)
    let inventoryValue = parseFloat(this.findDemandableTarget(target).dataset.plansInventory)
    let incompleteTaskMeasure = parseFloat(this.calculateMeasure(this.filteredIncompleteOperationTaskTargets(target)))
    let outstanding = ((parentValue - inventoryValue - incompleteTaskMeasure)*this.findDemandableTarget(target).dataset.plansRatio).toFixed(2)
    let carryover = (inventoryValue + incompleteTaskMeasure - parentValue).toFixed(2)
    let calculatedOutsanding = Math.max(outstanding, 0)
    let calculatedCarryover = Math.max(carryover, 0)
    this.updateRowTasksCount(target)
    this.updateRowTasksMeasure(target)
    this.updateRowOutstanding(target, calculatedOutsanding)
    this.updateRowCarryover(target, calculatedCarryover)
  }

  // Update Summary

  updateSummary() {
    this.updateSummaryTasksCount()
    this.updateSummaryTasksMeasure()
    this.updateSummaryTasksDisplayValue()
  }

  updateSummaryTasksCount() {
    this.summaryTasksCountTarget.innerHTML = this.operationTaskTargets.length
  }

  updateSummaryTasksMeasure() {
    if (this.hasSummaryTasksMeasureTarget) {
      this.summaryTasksMeasureTarget.innerHTML = this.calculateMeasure(this.operationTaskTargets).toFixed(0)
    }
  }

  updateSummaryTasksDisplayValue() {
    if (this.hasSummaryTasksDisplayValueTarget) {
      this.summaryTasksDisplayValueTarget.innerHTML = this.calculateDisplayValue(this.operationTaskTargets).toFixed(0)
    }
  }

  // Update Row

  updateRowOutstanding(target, value) {
    this.findOutstandingTarget(target).innerHTML = value
  }

  updateRowCarryover(target, value) {
    this.findCarryoverTarget(target).innerHTML = value
  }

  updateRowTasksCount(target) {
    if (this.hasTasksCountTarget) {
      this.findTasksCountTarget(target).innerHTML = this.operationTaskTargets.filter(task => task.dataset.taskableId == target.dataset.taskableId).length
    }
  }

  updateRowTasksMeasure(target) {
    if (this.hasTasksMeasureTarget) {
      this.findTasksMeasureTarget(target).innerHTML = this.calculateMeasure(this.filteredOperationTaskTargets(target))
    }
  }

  // Calculators

  calculateDisplayValue(targets) {
    var totalSum = 0

    targets.forEach((target) => {
      totalSum += parseFloat(target.dataset.displayValue)
    })
    return totalSum
  }

  calculateMeasure(targets) {
    var totalSum = 0

    targets.forEach((target) => {
      totalSum += parseFloat(target.dataset.measure)
    })
    return totalSum
  }

  // Finders

  findOutstandingTarget(target) {
    return this.outstandingTargets.find(outstanding => outstanding.dataset.plansTaskableId == target.dataset.taskableId)
  }

  findCarryoverTarget(target) {
    return this.carryoverTargets.find(carryover => carryover.dataset.plansTaskableId == target.dataset.taskableId)
  }

  findDemandableTarget(target) {
    return this.demandableTargets.find(demandable => demandable.dataset.plansTaskableId == target.dataset.taskableId)
  }

  findTasksCountTarget(target) {
    return this.tasksCountTargets.find(task => target.dataset.taskableId == task.dataset.plansTaskableId)
  }

  findTasksMeasureTarget(target) {
    return this.tasksMeasureTargets.find(task => target.dataset.taskableId == task.dataset.plansTaskableId)
  }

  // Filters

  filteredOperationTaskTargets(target) {
    return this.operationTaskTargets.filter(task => task.dataset.taskableId == target.dataset.taskableId)
  }

  filteredIncompleteOperationTaskTargets(target) {
    return this.operationTaskTargets.filter(task => task.dataset.completed != 'true' && task.dataset.taskableId == target.dataset.taskableId)
  }
}
