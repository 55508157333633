import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
export default class extends Controller {

  static targets = ['input', 'rangeSelect', 'startDate', 'endDate']

  static values = {
    minDate: String
  }

  connect() {
    if (this.hasInputTarget) {
      this.fp = flatpickr(this.inputTarget, {
        dateFormat: "M d Y",
        ...(this.hasMinDateValue && { minDate: `${this.minDateValue}` })
      });
    }
    if (this.hasRangeSelectTarget) {
      console.log('rangeSelect', this.rangeSelectTarget.value)
    }
  }

  setDates() {
    if (this.hasRangeSelectTarget && this.hasStartDateTarget) {
      const date_array = JSON.parse(this.rangeSelectTarget.value)
      this.startDateTarget.value = date_array[0]
      console.log(obj[0])
      console.log(JSON.parse(this.rangeSelectTarget.value))
      console.log(Array.from(this.rangeSelectTarget.value))
      console.log(this.rangeSelectTarget.value)
    }
  }

  clear() {
    this.fp.clear()
  }
}
