// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Alert, Tabs } from "tailwindcss-stimulus-components"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register('alert', Alert)
application.register('tabs', Tabs )

// Fixing Stimulus to report errors

application.handleError = (error, message, detail) => {
  console.warn(error, message, detail)
  if(typeof Honeybadger === 'object') {
    Honeybadger.notify(error)
  }
};
