import { Controller } from 'stimulus';

export default class extends Controller {
  setBasePrice(event) {
    const tomSelectInstance = event.target.tomselect;
    const selectedId = tomSelectInstance.items[0];
    const selectedItemData = tomSelectInstance.options[selectedId];

    if (selectedItemData) {
      const priceCents = `$${(selectedItemData.price_cents / 100.00).toFixed(2)}`;
      this.element.querySelector('.input.base-price input').value = priceCents;
    } else {
      console.error('Selected variant not found');
    }
  }
}
