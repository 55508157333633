import { Controller } from "stimulus"
// import { useTargetMutation } from 'stimulus-use'

/*

This controller enables selecting or unselecting all or multiple checkboxes on the page

Targets:
@parent - Parent checkbox for select All/None
@child  - Multiple child checkboxes
*/
export default class extends Controller {
  static targets = ['parent', 'child', 'countable', 'selectAction', 'unselectAction', 'selectedCount', 'totalCount', 'link']

  static values = { count: Number, params: String }

  connect() {
    // useTargetMutation(this, { targets: ["countable"] })
    this.toggleClass = this.data.get('class') || 'tw-hidden'
    this.setCount()
  }

  childTargetConnected() {
    this.setCount()
    this.sync()
  }

  childTargetDisconnected() {
    this.setCount()
    this.sync()
  }

  toggleSelection() {
    if (!this.hasParentTarget) return

    for (let child of this.childTargets) {
      child.checked = this.parentTarget.checked;
      child.dispatchEvent(new Event('change', { bubbles: true }));
    }

    this.setCount()
    this.setIds()
  }

  selectAll() {
    for (let child of this.childTargets) {
      if (!child.checked) {
        child.checked = true;
        child.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }

    this.sync()
  }

  unSelectAll() {
    for (let child of this.childTargets) {
      if (child.checked) {
        child.checked = false;
        child.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }

    this.sync()
  }

  selectCompleted() {

    for (let child of this.childTargets) {
      const isCompleted = child.dataset.completed == "true";
      if (child.checked != isCompleted) {
        child.checked = isCompleted;
        child.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }

    this.sync()
  }

  selectIncomplete() {

    for (let child of this.childTargets) {
      const isIncomplete = child.dataset.completed == "false";
      if (child.checked != isIncomplete) {
        child.checked = isIncomplete;
        child.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }

    this.sync()
  }

  sync() {
    let allChecked = this.allChildrenChecked()
    if (allChecked && this.childTargets.length != 0) {
      this.parentTarget.indeterminate = false
      this.parentTarget.checked = true
    } else if (this.childTargets.length === 0) {
      this.parentTarget.checked = false
      this.parentTarget.indeterminate = false
    } else {
      this.parentTarget.checked = false
      this.parentTarget.indeterminate = this.someChildrenChecked()
    }

    this.parentTarget.dispatchEvent(new Event('change', { bubbles: true }));

    this.setCount()
    this.setIds()
  }

  allChildrenChecked() {
    return this.checkedChildrenTargets().length === this.childTargets.length
  }

  someChildrenChecked() {
    return this.checkedChildrenTargets().length > 0;
  }

  noChildrenChecked() {
    return this.checkedChildrenTargets().length == 0
  }

  parentChecked() {
    return this.parentTarget.checked == true
  }

  checkedChildrenTargets() {
    return this.childTargets.filter(child => child.checked)
  }

  setCount() {
    this.countValue = this.checkedChildrenTargets().length

        
    if (this.hasSelectedCountTarget) {
      this.selectedCountTarget.innerHTML = this.checkedChildrenTargets().length
    }

    if (this.hasTotalCountTarget) {
      this.totalCountTarget.innerHTML = this.childTargets.length
    }
  }

  setIds() {
    if (!this.hasLinkTarget) return;

    const resourceIds = this.checkedChildrenTargets().map(element => element.dataset.resourceId)

    this.linkTarget.dataset.ids = resourceIds
    if (this.hasParamsValue) {
      this.linkTarget.querySelector(`[name='${this.paramsValue}']`).value = resourceIds
    }

  }

  countValueChanged() {
    let someChildrenChecked = this.someChildrenChecked()

    if (someChildrenChecked) {
      this.selectActionTargets.forEach(target => {
        target.classList.remove(this.toggleClass)
      })
      this.unselectActionTargets.forEach(target => {
        target.classList.add(this.toggleClass)
      })
    } else {
      this.selectActionTargets.forEach(target => {
        target.classList.add(this.toggleClass)
      })
      this.unselectActionTargets.forEach(target => {
        target.classList.remove(this.toggleClass)
      })
    }

  }
}
