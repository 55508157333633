import { Controller } from "stimulus";
export default class extends Controller {
  static outlets = [
    'checkbox-switch'
  ]

  toggle_checkbox_switches(event) {
    if (this.hasCheckboxSwitchOutlet) {
      this.checkboxSwitchOutlets.forEach(outlet => {
        outlet.switchTarget.checked = !outlet.switchTarget.checked
        outlet.toggle(event)
      })
    }
  }

  // Private

}
