import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "anchor", "input" ]

  connect() {
    this.encode()
  }

  encode() {
    for (const anchor of this.anchorTargets) {
      const url = this.createUrl(anchor);
      this.inputTargets.forEach(input => {
        if (input.value) {
          this.updateAnchor(url, input.name, input.value)
        }
      })
      anchor.search = url.search
    }
  }

  createUrl(anchor) {
    const baseUrl = anchor.tagName === 'BUTTON' ? new URL(anchor.formAction) : new URL(anchor.href);
    const baseUrlWithoutParams = baseUrl.origin + baseUrl.pathname;
    return new URL(baseUrlWithoutParams);
  }

  intercept(event) {
    event.preventDefault()
  }

  clickButton(event) {
    event.target.click()
  }

  updateAnchor(url, name, value) {
    url.searchParams.append(name, value)
  }
}
