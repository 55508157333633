import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['green', 'roast']
  static values = { ratio: Number }

  calculateGreen() {
    this.timeout = setTimeout(() => {
      this.displayGreenResult()
    }, this.delay)
  }

  displayGreenResult() {
    if (this.hasGreenTarget) {
        let result = (this.roastTarget.value*this.ratio).toFixed(4)
        console.log("Calculated green result:", result)
        this.greenTarget.value = result
      }
  }

  calculateRoast() {
    this.timeout = setTimeout(() => {
      this.displayRoastResult()
    }, this.delay)
  }

  displayRoastResult() {
    if (this.hasRoastTarget) {
        let result = (this.greenTarget.value/this.ratio).toFixed(4)
        console.log("Calculated roast result:", result)
        this.roastTarget.value = result
      }
  }

  get delay() {
    return 350
  }

  get ratio() {
    return this.ratioValue || 1
  }
}