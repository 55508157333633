import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['order', 'selected'];

  async importSelected(event) {
    event.preventDefault();
    const OrderIds = this.checkedOrderTargets().map(element => element.dataset.id);

    await fetch('/commerce/orders/import', {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'order_ids': OrderIds }),
    });
  }

  async checkImportability(event) {
    event.preventDefault();
    const OrderIds = this.checkedOrderTargets().map(element => element.dataset.id);

    await fetch(`/commerce/orders/importability_checks`, {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'order_ids': OrderIds }),
    });
  }

  toggle(event) {
    var id = event.target.closest('tr').getAttribute('data-id');
    var element = document.getElementById(`commerce-order-${id}-expandable`);
    if (element.hidden) {
      element.hidden = false;
    } else {
      element.hidden = true;
    }
  }

  importOrders(event) {
    event.preventDefault();
    const OrderIds = this.checkedOrderTargets().map(element => element.dataset.id);
    let id = document.getElementById("rutter_connection_id").value;
    let url = `/integrations/commerce/${id}/setup/import_orders/import`;
    let redirectUrl = `/integrations/commerce/${id}/setup/summary`;

    fetch(url, {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'selected_order_ids': OrderIds }),
    });
    window.location.href = redirectUrl;
  }

  async updateImportStatus(event) {
    event.preventDefault();
    const OrderIds = this.checkedOrderTargets().map(element => element.dataset.id);
    const importStatus = event.target.closest('[data-import-status]').getAttribute('data-import-status');

    await fetch('/commerce/orders/import_statuses', {
      method: 'PATCH',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'order_ids': OrderIds, 'import_status': importStatus }),
    });
  }

  // private

  checkedOrderTargets() {
    return this.orderTargets.filter(order => order.querySelector('input').checked);
  }


  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}
