import ApplicationController from '../application_controller';
import CableReady from 'cable_ready'
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  connect() {
    consumer.subscriptions.create(
      {
        channel: "FulfillChannel",
        resource_id: this.element.dataset.resourceId,
        resource_type: this.element.dataset.resourceType,
      },
      {
        received (data) {
          if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
}
