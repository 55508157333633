import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['price', 'variant']

  connect() {}

  changeAll(event) {
    const customerId = event.target.value;
    if (customerId) {
      this.data.set("customerId", customerId);
      this.variantTargets.forEach(data => {
        const variantSelect = data.querySelector(".order_line_items_variant_id");
        const variantId = variantSelect.value;
        const basePriceInput = data.querySelector(".order_line_items_variant_price").firstElementChild;
        if (variantId) {
          this.updatePrice(customerId, variantId, basePriceInput);
        }
      })
    }
  }

  change(event) {
    const customerId = this.data.get("customerId");
    if (customerId) {
      const variantId = event.target.options[event.target.selectedIndex].value;
      const parentElement = event.target.parentNode.parentNode;
      const fulfillCheckbox = parentElement.querySelector(".order_line_items_fulfill_checkbox");
      const basePriceInput = parentElement.querySelector(".order_line_items_variant_price").firstElementChild;
      const inventoryElement = parentElement.querySelector(".inventory");
      if (variantId) {
        this.updatePrice(customerId, variantId, basePriceInput, inventoryElement, fulfillCheckbox);
      }
    }
  }

  // private

  updatePrice(customerId, variantId, basePriceInput, inventoryElement, fulfillCheckbox) {
    fetch(`/customers/${customerId}/variants/${variantId}`)
      .then(response => response.json())
      .then(json => {
        if (inventoryElement) {
          inventoryElement.innerHTML = json.available_inventory;
        }
        if (fulfillCheckbox) {
          fulfillCheckbox.checked = json.auto_fulfillable;
          fulfillCheckbox.disabled = false;
        }
        basePriceInput.value = json.price.toFixed(2);
        basePriceInput.dispatchEvent(new Event('change', { bubbles: true }));
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
