import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']

  connect() {
  }

  load(event) {
    this.containerTarget.insertAdjacentHTML('afterbegin','<span class="element-spinner masked"></span>')
    setTimeout(() => {
      const customerId = event.target.options[event.target.selectedIndex].value
      if (customerId) {
        let urlParams = ""
        urlParams = `?${this.data.get("param-key")}=${customerId}`
        fetch(this.data.get("url") + urlParams)
          .then(response => response.text())
          .then(html => {
            const parser = new DOMParser();

            // Parse the text
            const parsed_html = parser.parseFromString(html, "text/html");
            const content = parsed_html.querySelector('[data-content-loader-from-selector-target="container"]');
            this.containerTarget.innerHTML = content.innerHTML;
          })
      } else {
        this.containerTarget.querySelector('.element-spinner').remove()
      }
    }, 5)
  }
}