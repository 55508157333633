import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['highlightable']

  static classes = [ "background" ]

  connect() {
    if (this.hasHighlightableTarget && this.highlightableItem()) {
      this.highlight()
      this.highlightableItem().scrollIntoView()
    }
  }

  // private

  highlight() {
    this.highlightableItem().classList.add('tw-bg-teal-50')
  }

  highlightableItem() {
    return this.highlightableTargets.find(item => item.dataset.highlightDomId == this.domId)
  }

  get domId() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('highlight')
  }
}