import ApplicationController from '../application_controller';
import CableReady from 'cable_ready'
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  connect() {
    consumer.subscriptions.create(
      {
        channel: "InventoryCountChannel",
        inventory_count_id: this.element.dataset.inventoryCountId
      },
      {
        connected () {
          console.log("OperationTaskChannel Connected")
        },
        received (data) {
          console.log("Inside InventoryCountController#received")
          if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
}