import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    if (this.element.tagName == "SELECT") {
      this.element.querySelector("option:not([value=''])")?.
        setAttribute('selected', true)

      // Safari hack to ensure that the first option is selected
      let selectedValue = this.element.querySelector("option:not([value=''])")?.value
      this.element.value = selectedValue

      this.element.dispatchEvent(new Event("change"))
    }
  }
}