import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['output']
  
  static values = { 
    delay: Number, default: 0,
  }
  static outlets = [
    'tom-select',
    'conversion'
  ]

  update(element, value) {
    this.timeout = setTimeout(() => {
      this.updating(element, value)
    }, this.delayValue)
  }

  updating(element, value) {
    if (!element.disabled) {
      element.value = value
      if (element.tomselect) {
        element.tomselect.setValue(value)
      }
    }
    
    const e = new Event("change");
    this.outputTarget.dispatchEvent(e)
  }

  // Private


  getContentValue(event) {
    switch(this.inputValue) {
      case 0:
        // Copy source's value
        return event.target.value
      case 1:
        // Copy the event's data-content-value
        return event.target.dataset.contentValue
    }
  }

  paste(event) {
    console.log('pasting!')
    console.log('getContentValue', this.getContentValue(event))
    this.timeout = setTimeout(() => {
      this.pasting(event)
    }, this.delayValue)
  }

  pasting(event) {
    let contentValue = (this.hasUseTargetInputValue && this.useTargetInputValue) ?
      this.inputTarget.value :
      event.target.dataset.contentValue
    if (this.hasOutputTarget) {
      console.log('has output target', this.outputTargets.length)
      console.log('contentValue', contentValue)
      this.outputTargets.forEach(target => {
        target.value = contentValue
      })
    }

    const e = new Event("change");
    this.outputTarget.dispatchEvent(e)
  }

  pasting_from_tom_select(event) {
    let contentValue = event.target.value
    if (this.hasOutputTarget) {
      console.log('has output target', this.outputTargets.length)
      console.log('contentValue', contentValue)
      this.outputTargets.forEach(target => {
        target.value = contentValue
      })
    }
  }
}
