import { Controller } from 'stimulus';
import {enter, leave} from 'el-transition';
export default class extends Controller {
  static targets = ['container', 'background', 'frame', 'loader'];

  submit(e) {
    if (e.detail.success) {
      this.close(e)
    }
  }

  open() {
    // Unhide the background
    enter(this.backgroundTarget)

    // Unhide the modal
    enter(this.containerTarget)
    // this.containerTarget.classList.remove("tw-hidden");
  }

  close(e) {
    if (e) {
      e.preventDefault();
    }

    // Hide the modal
    leave(this.containerTarget)
    this.frameTarget.removeChild(this.frameTarget.firstElementChild)

    // Remove the background
    leave(this.backgroundTarget)
  }

  closeBackground(e) {
    if (e.target === this.backgroundTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains("tw-hidden")) {
      this.close(e);
    }
  }
}
