import { Controller } from 'stimulus'

// This controller is a direct port of the old
// CoffeeScript logic.
// https://github.com/roastertools/roastertools/blob/main/app/assets/javascripts/portal/pages/products/show.coffee
// It's a very brittle way of achieving this use case.
// Ideally, the client should send the selected options to the server
// and ask it for the appropriate variant. Perhaps this can happen within a
// Turbo Frame which is refreshed every time an option is selected.
// That's a massive refactor, which is why I've replicated the logic for now.
// This should be addressed sooner rather than later.
export default class extends Controller {
  static values = { targetId: String }
  static targets = [ "select" ]

  updateVariant() {
    let selectedOptions = this.selectTargets.map((select) => {
      return select.value
    }).sort().join(", ")

    let variantSelect = document.getElementById(this.targetIdValue)
    variantSelect.querySelectorAll("option").forEach((option) => {

      if (option.dataset.optionValueIds) {
        let valueIds = option.dataset.optionValueIds.toString().split(", ").sort().join(", ")

        if (selectedOptions == valueIds) {
          variantSelect.selectedIndex = option.index
          variantSelect.dispatchEvent(new Event('change'))
        }
      }
    })
  }
}