import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['button']

  static values = { platform: String, publicKey: String }

  loadRutter() {
    // eslint-disable-next-line
    var rutterInstance = Rutter.create({
      publicKey: this.publicKeyValue,
      onSuccess: (publicToken) => {
        // Send publicToken to your backend and exchange
        // https://docs.rutterapi.com/reference#exchange-tokens
        this.callBackendAPI(this.buttonTarget, publicToken)
      }
    })

    if (this.hasPlatformValue) {
      rutterInstance.open({platform: this.platformValue})
    } else {
      rutterInstance.open()
    }

  }

  callBackendAPI(buttonTarget, publicToken) {
    const accountId = buttonTarget.dataset.rutterAccountId
    const url = buttonTarget.dataset.rutterUrl.replace(":id", accountId)

    fetch(url, {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "public_token": publicToken, "account_id": accountId })
    }).then(response => response.json())
      .then(data => {
        if (data.id != null && data.platform_type == 'commerce') {
          window.location.href = '/integrations/commerce/' + data.id + '/setup';
        } else {
          window.location.href = '/integrations/accounting/' + data.id + '/setup';
        }
      })
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}