import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.updatePrice()
  }

  updatePrice() {
    let variantId = this.element.value

    // My preferred approach is to define the path in a value
    // or `data-` attribute and read it into the controller.
    // However, several `select`s on the same page use this
    // controller in the same way using the same path.
    // In the interest of de-duplication, I've hardcoded the path
    // in the controller.
    if (variantId) {
      let path = '/customer_variants/' + variantId

      this.productPriceFrame.setAttribute("src", path)
    }
  }

  // This isn't ideal. Stimulus controllers shouldn't usually
  // muck about with elements outside its scope.
  // Replace with the Outlets API when Stimulus 3.2 is released:
  // https://github.com/hotwired/stimulus/pull/576
  get productPriceFrame() {
    return document.querySelector("turbo-frame#product_price")
  }
}
