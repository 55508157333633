import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    sourceId: String,
    optionSelector: String
  }

  connect() {
    this.sourceController =
      super.controllerForElement(
        "chained-selects",
        document.querySelector(`select#${this.sourceIdValue}`)
      )

    this.sourceController.chainTo(this)
  }

  showOptions(value) {
    this.hideAllOptions()

    let selector = this.optionSelectorValue.replace(/{value}/, value)
    let options = this.element.querySelectorAll(`option${selector}`)

    options.forEach((option) => {
      option.hidden = false
      option.removeAttribute('disabled')
    })

    this.autoSelect(options[0])
  }

  // PRIVATE

  hideAllOptions() {
    this.allOptions.forEach((option) => {
      option.hidden = true
      option.setAttribute('hidden', '')
      option.setAttribute('disabled', 'disabled')
    })

    this.element.selectedIndex = 0
  }

  autoSelect(option) {
    this.element.selectedIndex = option.index
    this.element.dispatchEvent(new Event("change"))
  }

  get allOptions() {
    return this.element.querySelectorAll("option")
  }
}