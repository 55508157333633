import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.setName()
  }

  setName() {
    if (this.hasInputTarget) {
      this.inputTargets.forEach(target => {
        if (target.value == '') {
          target.name = ''
        } else {
          target.name = target.id
        }
      })
    }
  }
}