import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]

  enable() {
    this.buttonTarget.classList.remove("tw-invisible")
    this.buttonTarget.removeAttribute('disabled')
  }

  toggle() {
    this.buttonTarget.classList.add("tw-invisible")
    this.buttonTarget.setAttribute('disabled', 'disabled')
  }
}
