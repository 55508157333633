import { Controller } from "stimulus"
import { useTargetMutation } from 'stimulus-use'

export default class extends Controller {

  static targets = ['container', 'item', 'banner']

  static classes = [ "hidden" ]

  connect() {
    useTargetMutation(this, { targets: ["item"] })
  
    this.updateBanner()
  }

  itemTargetAdded() {
    this.updateBanner()
  }

  itemTargetRemoved() {
    this.updateBanner()
  }

  removeBanner() {
    if (this.totalHeight() === this.containerTarget.scrollHeight) {
      this.bannerTarget.classList.add(this.hiddenClass)
    }
  }

  addBanner() {
    if (this.totalHeight() != this.containerTarget.scrollHeight) {
      this.bannerTarget.classList.remove(this.hiddenClass)
    }
  }

  scrollDown() {
    this.containerTarget.scrollBy({top: this.containerTarget.scrollHeight, left: 0, behavior: 'smooth'})
  }

  updateBanner() {

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    if (this.hasItemTarget) {

      const itemScrollHeights = this.itemTargets.map(element => element.scrollHeight)

      const itemScrollHeightsTotal = itemScrollHeights.reduce(reducer)
    
      if (this.containerTarget.clientHeight < itemScrollHeightsTotal) {
        this.bannerTarget.classList.remove(this.hiddenClass)
      } else {
        this.bannerTarget.classList.add(this.hiddenClass)
      }

    }
    this.removeBanner()
  }

  // private

  totalHeight() {
    return (this.containerTarget.scrollTop + this.containerTarget.clientHeight)
  }

}