import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "justifiPaymentProvisioningForm"
  ];

  static values = {
    paymentProcessorId: String
  }

  connect() {
    var paymentProvisioning = this.justifiPaymentProvisioningFormTarget;
    paymentProvisioning.addEventListener('submitted', this.handleOnboardingSubmission);
  }

  handleOnboardingSubmission = (event) => {
    const data = event.detail.data;
    const { response = {} } = data || {};
    const { error_message, data: { sub_account_id } = {} } = response;

    if (error_message) {
      this.notifyError(error_message);
    } else if (sub_account_id) {
      this.handleSuccess(sub_account_id);
    }
  };

  handleSuccess(subAccountId) {
    const paymentProcessorId = this.paymentProcessorIdValue;

    fetch(`/payment_processors/${paymentProcessorId}/provisionings`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ processor_account_id: subAccountId })
    }).then(
      response => response.json())
      .then(data => {
          if (data.redirect_url) {
            window.location.href = data.redirect_url;
          }
          else
          {
            console.error('No redirect URL found');
          }
        })
  }

  notifyError(error) {
    if (typeof Honeybadger === "object") {
      Honeybadger.notify(error);
    } else {
      console.warn(error);
    }
  }
}
