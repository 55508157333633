import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dateInput']

  connect() {
    console.log("Hello dynamicValueFromInput")
  }

  change(event) {
    const value = event.target.value;
    if (this.hasDateInputTarget) {
      const offset = parseInt(this.dateInputTarget.dataset.offset) || 0;
      let date = new Date(value)
      let tz_offset = new Date(value).getTimezoneOffset()

      date.setMinutes(date.getMinutes() + tz_offset)
      date.setDate(date.getDate() + offset)

      var fp = this.dateInputTarget._flatpickr
      fp.setDate(date, "M d Y")

      let formattedDate = fp.formatDate(date, "M d Y")
      this.dateInputTarget.value = formattedDate
    }
  }
}