import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.inputTarget.style.appearance = "textfield"
  }

  increment() {
    this.inputTarget.value = this.currentIntegerValue + 1
  }

  decrement() {
    let decrementedValue = this.currentIntegerValue - 1
    this.inputTarget.value = Math.max(0, decrementedValue)
  }

  get currentIntegerValue() {
    return parseInt(this.inputTarget.value)
  }
}