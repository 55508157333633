import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['date', 'customers'];

  connect() {
    this.setSalesData();
  }

  setSalesData() {
    const date = this.dateTarget.value;
    fetch(`/dashboard/sales?date=${date}`)
      .then((response) => response.json())
      .then((data) => {
        this.customersTarget.innerHTML = data.html;
      });
  }
}
