import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
  }

  feedback() {
    if (typeof Beacon != 'undefined') {
      Beacon('reset'); Beacon('toggle'); Beacon('prefill', { subject: "Here's some feedback" }); Beacon('navigate', '/ask/message/');
    }
  }

  message() {
    if (typeof Beacon != 'undefined') {
      Beacon('reset'); Beacon('toggle'); Beacon('navigate', '/ask/message/');
    }
  }
}