import ApplicationController from '../application_controller';
import CableReady from 'cable_ready'
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  connect() {
    console.log("Hello Nested controller")
    consumer.subscriptions.create(
      {
        channel: "OperationTaskChannel",
        task_name: this.element.dataset.taskName,
        account_id: this.element.dataset.accountId
      },
      {
        connected () {
          console.log("OperationTaskChannel Connected")
        },
        received (data) {
          console.log("Inside #received")
          if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
}
