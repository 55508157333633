import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wrapper"]

  change(event) {
    console.log('change')
    this.element.addEventListener('ajax:before', this.handleBefore.bind(this))
    this.element.addEventListener('ajax:complete', this.handleComplete.bind(this))
    this.element.dispatchEvent(new Event('submit', {bubbles: true}))
  }

  handleBefore() {
    this.wrapperTarget.classList.toggle('element-spinner')
    document.body.classList.toggle('no-pointer-events')
  }

  handleComplete() {
    this.wrapperTarget.classList.toggle('element-spinner')
    document.body.classList.toggle('no-pointer-events')
  }
}