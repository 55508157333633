import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['value', 'result']

  connect() {
    this.calculate();
  }

  calculate() {
    let elements = [this.valueTarget.value];
    elements.push(this.data.get('operator'))
    elements.push(this.data.get('other-value'))
    const result = eval(elements.join(' '));
    this.resultTarget.innerHTML = this.pluralizedResult(this.addPlus(result.toFixed(2)), this.data.get('unit'));
    if (result < 0) {
      this.resultTarget.classList.add("text-danger")
      this.resultTarget.classList.remove("text-warning")
      this.resultTarget.classList.remove("tw-text-zinc-800")
    } else if (result > 0) {
      this.resultTarget.classList.add("text-success")
      this.resultTarget.classList.remove("tw-text-zinc-800")
      this.resultTarget.classList.remove("text-danger")
    } else {
      this.resultTarget.classList.add("tw-text-zinc-800")
      this.resultTarget.classList.remove("text-warning")
      this.resultTarget.classList.remove("text-danger")
    }
  }

  // Private

  pluralizedResult(count, noun, suffix = 's') {
    return `${ count } ${ noun }${count !== 1 ? suffix : ''}`
  }

  // add a + when the value is positive
  addPlus(value) {
    return value > 0 ? `+${value}` : value
  }
}
