import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainItem", "reverting", "adjusting", "card", "adjustmentContainer", "transformationContainer", "reasonsSelector"]

  connect() {
    this.calculateValue();
    this.showProperArrows()
    this.toggleAdjustmentData()
  }

  matchUnit() {
    const selector = this.mainItemTarget.querySelector(".merged-wrapper-right select");
    const weight_unit = selector.options[selector.selectedIndex].text;
    this.cardTargets.forEach(card => {
      card.querySelector(".weight_unit").innerHTML = weight_unit;
    });
  }

  showProperArrows() {
    this.element.querySelector(".create-arrow").style.display = this.shouldRevert ?  "none" : "block";
    this.element.querySelector(".revert-arrow").style.display = this.shouldRevert ?  "block" : "none";
    this.cardTargets.forEach(card => {
      if ((card.dataset.main && !this.shouldRevert) || (!card.dataset.main && this.shouldRevert)) {
        card.classList.add("green-card");
      } else {
        card.classList.remove("green-card");
      }
    });
  }

  toggleAdjustmentData() {
    this.adjustmentContainerTarget.style.display = this.adjusting ? "block" : "none"
    this.transformationContainerTarget.style.display = this.adjusting ? "none" : "block"
    this.reasonsSelectorTarget.style.display = this.adjusting ? "block" : "none"
  }

  calculateValue() {
    this.cardTargets.forEach(card => {
      const ratio = card.dataset.ratio;
      card.querySelector(".weight_value").innerHTML = this.roundFloat(ratio * this.mainValue);
    });
  }

  // Private //

  get mainValue() {
    return this.mainItemTarget.querySelector(".merged-wrapper-left input").value;
  }

  get shouldRevert() {
    return this.revertingTarget.value == "1"
  }

  get adjusting() {
    return this.adjustingTarget.value == "1"
  }

  roundFloat(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }
}
