import { Controller } from "stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.min.css";
export default class extends Controller {
  static targets = ['select', 'option', 'optionTemplate', 'itemTemplate']
  static values = {
    url: String,
    filters: Object,
    searchField: Array
  }
  static outlets = [
    'copy'
  ]

  initialize() {
    this.options = this.options.bind(this)
    this.optionsReducer = this.optionsReducer.bind(this)
    this.remoteLoad = this.remoteLoad.bind(this)
    this.multipleItemsFix = this.multipleItemsFix.bind(this)
  }

  connect() {
    setTimeout(this.setup.bind(this), 5)
  }

  disconnect() {
  }

  setup() {
    let plugins = this.getPlugins()
    this.tomSelect = new TomSelect(this.selectTarget, {
      ...this.mergedOptions,
      plugins: plugins,
      },
    );
  }

  setValue(value) {
    this.tomSelect.setValue(value)
  }

  disable()	{
    this.tomSelect.disable()
  }

  enable()	{
    this.tomSelect.enable()
  }

  clicked(event) {
    if (this.hasCopyOutlet) {
      this.copyOutlet.pasting_from_tom_select(event)
    }
  }

  remoteLoad(urlValue, params = {}) {
    return {
      load: function(query, callback) {
        var url = `${urlValue}?query=${encodeURIComponent(query)}`;
        for (var key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            url += `&${key}=${encodeURIComponent(params[key])}`;
          }
        }
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json);
          }).catch(()=>{
            callback();
          });
      }
    }
  }

  multipleItemsFix() {
    return {
      onItemAdd: function() {
        this.setTextboxValue('');
        this.refreshOptions();
      }
    }
  }

  getPlugins() {
    const plugins = []
    if (typeof this.selectTarget.dataset.tomSelectDropdownInput !== 'undefined') {
        plugins.push('dropdown_input')
    }
    if (typeof this.selectTarget.dataset.tomSelectRemoveButton !== 'undefined') {
      plugins.push('remove_button')
    }
    return plugins
  }

  options() {
    return 'dropdownParent create maxOptions sortField maxItems valueField labelField searchField onItemAdd closeAfterSelect optgroupValueField optgroupLabelField'
      .split(' ')
      .reduce(this.optionsReducer, {})
  }

  optionsReducer(accumulator, currentValue) {
    if (this.hasOptionTarget && this.optionTarget.dataset[currentValue])
        accumulator[currentValue] = this.optionTarget.dataset[currentValue]
      if (this.hasSearchFieldValue) {
        // searchField: ['title', 'description'] can be an array with multiple values
        accumulator['searchField'] = this.searchFieldValue
      }
    return accumulator
  }

  defaultOptions() {
    return {
      dropdownParent: 'body',
      create: false,
      maxOptions: 25,
      valueField: 'id',
      render: {
        loading: () => '<span class="tw-flex tw-items-center tw-py-3 tw-px-2 tw-text-zinc-400 tw-text-sm">' + '<svg class="tw-stroke-current tw-fill-current tw-animate-spin tw-text-teal-400 tw-h-4 tw-w-4 tw-mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs><style>.fa-secondary{opacity:.4}</style></defs><path d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 0 1-6.94-19.2A184 184 0 1 1 256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0 1 15.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z" class="fa-secondary"></path><path d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 0 1-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 0 0 440 256c0-96.49-74.27-175.63-168.77-183.38z" class="fa-primary"></path></svg>' + ' Searching... ' + '</span>',
      },
    };
  }

  get mergedOptions() {
    const baseOptions = {
      ...this.defaultOptions(),
      ...(this.selectTarget.multiple == true && this.multipleItemsFix()),
      ...(this.hasUrlValue && this.remoteLoad(this.urlValue, this.filtersValue)),
      ...(this.hasOptionTarget && this.options())
    };
  
    if (this.hasOptionTemplateTarget) {
      baseOptions.render.option = (data, escape) => this.renderTemplate(this.optionTemplateTarget, data, escape);
    }
  
    if (this.hasItemTemplateTarget) {
      baseOptions.render.item = (data, escape) => this.renderTemplate(this.itemTemplateTarget, data, escape);
    }
  
    return baseOptions;
  }

  renderTemplate(template, data, escape) {
    const content = template.content.cloneNode(true).firstElementChild;
    const elements = content.querySelectorAll('[data-content-attribute]');
    const parsedData = this.parseDataFromString(data.text);
  
    elements.forEach(element => {
      const attribute = element.getAttribute('data-content-attribute');
      const value = parsedData[attribute];
      if (value) {
        element.textContent = escape(value);
      }
    });
  
    return content;
  }

  // Parses a string that mimics a Ruby hash into an object
  parseDataFromString(dataString) {
    let parsedData = {};
    try {
      // Assuming the format: '{:key=>"value", :key2=>"value2"}'
      const keyValuePairs = dataString.slice(1, -1).split(/,\s*(?=:)/); // Split by comma followed by a colon
      keyValuePairs.forEach(pair => {
        const [key, value] = pair.split('=>');
        if (key && value) {
          const cleanKey = key.trim().replace(/[:"]+/g, ''); // Remove colons and quotes from key
          const cleanValue = value.trim().replace(/^"|"$/g, ''); // Remove surrounding quotes from value
          parsedData[cleanKey] = cleanValue;
        }
      });
    } catch (error) {
      console.error('Error parsing data:', error);
    }
    return parsedData;
  }
}
