import consumer from './consumer'

const messagesContainer = document.getElementById("messagesContainer")
if (messagesContainer) {
  consumer.subscriptions.create({ channel: 'TestChannel', id: "1" }, {
    received (data) {
      messagesContainer.insertAdjacentHTML('beforeend', data)
    }
  })
}
