import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    document.addEventListener("turbo:before-fetch-request", async (event) => {
      event.target.classList.add("tw-opacity-25")
    })

    document.addEventListener("turbo:frame-render", async (event) => {
      event.target.classList.remove("tw-opacity-25")
    })
  }
}
