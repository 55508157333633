import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal']

  connect() {
  }

  open() {
    this.modalTarget.classList.add("in");
    this.modalTarget.setAttribute("style", "display: block;");
    document.body.classList.add("modal-open");
    const backdrop = document.createElement('div');
    backdrop.classList.add('modal-backdrop', 'fade', 'in');
    document.body.appendChild(backdrop);
  }

  close() {
    this.modalTarget.removeAttribute("style", "display: block;");
    this.modalTarget.classList.remove("in");
    document.body.classList.remove("modal-open");
    document.body.querySelector('.modal-backdrop').remove();
    this.modalTarget.querySelector(".modal-body").innerHTML = '<span class="element-spinner masked"></span>'
  }
}
