import { Controller } from "stimulus"
import Sortable from "sortablejs";
// import { Sortable, MultiDrag, Swap, OnSpill, AutoScroll } from "sortablejs";

export default class extends Controller {

  static targets = ['date', 'group', 'index', 'display']

  static classes = [ "handle", "draggable", "ghost" ] 

  static values = {
    type: String,
    page: Number,
    pagyOffset: Number,
    form: Boolean
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      ...(this.hasHandleClass && { handle: `.${this.handleClass}` }),
      ...(this.hasDraggableClass && { draggable: `.${this.draggableClass}` }),
      ...(this.hasGroupTarget && { group: 'group' }),
      ...(this.hasGhostClass && { ghostClass: `${this.ghostClass}` }),
      animation: 150,
      emptyInsertThreshold: 25,
      onEnd: this.persist.bind(this)
    })
  }

  // private

  persist(event) {

    if (this.hasIndexTarget) {
      var index = 0;
      this.indexTargets.forEach(indexTarget => {
        indexTarget.setAttribute('value', index)
        index += 1
      });
      var display_index = 1;
      this.displayTargets.forEach(displayTarget => {
        displayTarget.innerHTML = display_index
        display_index += 1
      });
    }

    if (this.hasTypeValue && this.typeValue == 'date') {
      const date = event.to.dataset.draggableDate;
      const resourceId = event.item.dataset.resourceId;
      const url = this.url.replace(":id", resourceId);
      fetch(url, {
        method: 'PUT',
        headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ "date": date })
      });
    } else if (this.hasIndexTargets) {
      this.indexTargets.forEach(indexTarget => {
        indexTarget.setAttribute('value', this.calculatedIndex(event))
      });
     } else if (this.url) {
      const resourceId = event.item.dataset.resourceId;
      const url = this.url.replace(":id", resourceId);
      fetch(url, {
        method: 'PUT',
        headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ "index": this.calculatedIndex(event) })
      });
    }
  }

  calculatedIndex(event) {
    let calculatedIndex = event.newIndex
    if (this.hasPageValue && this.hasPagyOffsetValue) {
      calculatedIndex = this.pagyOffsetValue + event.newIndex
    }
    console.log('calculatedIndex', calculatedIndex)
    return calculatedIndex
  }

  get url() {
    return this.data.get("url");
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
