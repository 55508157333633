import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["product", "selected"]

  connect() {
    console.log("Hello Products")
    console.log("product count:", this.productTargets.length)
  }

  addToPortal(event) {
    event.preventDefault();
    const productIds = this.checkedProductTargets().map(element => element.dataset.productId);
    fetch('products/bulk/portal_availability', {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "product_ids": productIds })
    });
  }

  removeFromPortal(event) {
    event.preventDefault();
    const productIds = this.checkedProductTargets().map(element => element.dataset.productId);
    fetch('products/bulk/portal_availability', {
      method: 'DELETE',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "product_ids": productIds })
    });
  }

  deactivate(event) {
    event.preventDefault();
    const productIds = this.checkedProductTargets().map(element => element.dataset.productId);
    fetch('products/bulk/status', {
      method: 'DELETE',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "product_ids": productIds })
    });
  }

  activate(event) {
    event.preventDefault();
    const productIds = this.checkedProductTargets().map(element => element.dataset.productId);
    fetch('products/bulk/status', {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "product_ids": productIds })
    });
  }

  markSelectedAsIncomplete(event) {
    event.preventDefault();
    const productIds = this.checkedProductTargets().map(element => element.dataset.productId);
    this.checkedProductTargets().forEach(element => {
      if (element.dataset.completed != 'false') {
        let domId = element.dataset.domId
        document.getElementById(domId).innerHTML = this.replacementContainerTarget.innerHTML;
      }
    });
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "product_ids": productIds, "completed": false })
    });
  }

  markAllAsComplete(event) {
    event.preventDefault();
    const productIds = this.productTargets.map(element => element.dataset.productId);
    this.productTargets.forEach(element => {
      if (element.dataset.completed != 'true') {
        let domId = element.dataset.domId
        document.getElementById(domId).innerHTML = this.replacementContainerTarget.innerHTML;
      }
    });
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ "product_ids": productIds, "completed": true })
    });
  }


  destroySelected(event) {
    event.preventDefault();
    var response = confirm(event.currentTarget.dataset.confirm)
    if (response) {
      const productIds = this.checkedProductTargets().map(element => element.dataset.productId);
      this.replaceableTargets.forEach(element => {
        if (element.dataset.completed != 'false') {
          element.innerHTML = this.replacementContainerTarget.innerHTML;
        }
      });
      const url = event.currentTarget.dataset.url;
      fetch(url, {
        method: 'DELETE',
        headers: { 'X-CSRF-Token': this.token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ "product_ids": productIds })
      });
    } else {
      return
    }

  }

  // private

  checkedProductTargets() {
    return this.productTargets.filter(product => product.checked)
  }


  get token() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
